<template>
  <go class="link" :to="to">
    <slot />
    <link-svg />
  </go>
</template>

<script>
import LinkSvg from '@/assets/images/Link.svg?inline';

export default {
  components: {
    LinkSvg,
  },
  props: {
    to: { type: String, default: null },
  },
};
</script>

<style lang="scss" scoped>
svg {
  stroke-width: 0.75;
  fill: none;
  stroke: currentColor;
  height: 1em;
  display: inline-block;
  margin-left: .5rem;
}

.link {
  cursor: pointer;
  transition: color var(--speed-fast);

  &:hover {
    color: var(--color-active);
  }
}
</style>
