<template>
  <header>
    <section id="banner">
      <swiper
        :space-between="0"
        pagination
        class="swiper slider banner-swiper"
      >
        <swiper-slide
          v-for="(item, index) in content?.banner"
          :key="index"
          class="swiper-slide"
        >
          <Image class="banner-image" :src="item" />
        </swiper-slide>
      </swiper>

      <div class="band" :class="{ open: contactOpen, mobile }" @mouseleave="closeContact">
        <div class="content">
          <div class="logo">
            <Go :to="'/' + $store.state.router.locale" aria-label="Home">
              <transition name="fade" mode="out-in">
                <component :is="logo" />
              </transition>
            </Go>
          </div>

          <div v-if="mobile" class="mobile-wrapper">
            <div class="mobile-nav" :class="{ active: mobile }">
              <div class="menu-header">
                {{ layout.menuText }}
              </div>
              <div class="menu">
                <accordion :items="navigation?.links">
                  <template #header="{ item: link, expanded }">
                    <span
                      v-if="!expanded && link.subs.length"
                      class="menu-item"
                      :class="{
                        active: link.uri === $store.state.router.page.meta?.uri,
                      }"
                      v-text="link.title"
                    />
                    <Go
                      v-else
                      class="menu-item main"
                      :to="link.uri"
                      :class="{
                        active: expanded || link.uri === $store.state.router.page.meta?.uri,
                      }"
                      @click="toggle()"
                      v-text="link.title"
                    />
                  </template>
                  <template #default="{ item: link }">
                    <div v-if="link.subs.length" class="submenu">
                      <Go
                        v-for="sub in link.subs"
                        :key="sub.id"
                        :to="sub.uri"
                        class="sub menu-item"
                        :class="{ active: sub.uri === $store.state.router.page.meta?.uri }"
                        @click="toggle()"
                        v-text="sub.title"
                      />
                    </div>
                  </template>
                </accordion>
              </div>
              <div class="social">
                <Go v-if="layout.contact.location.phone" :to="`tel:${layout.contact.location.phone}`">
                  <phone />
                </Go>
                <Go v-if="layout.contact.location.mail" :to="`mailto:${layout.contact.location.mail}`">
                  <mail />
                </Go>
                <Go
                  v-for="locale in languages"
                  :key="locale"
                  :to="getPath(locale)"
                  class="icon-like"
                >
                  {{ capitalize(locale) }}
                </Go>
              </div>
            </div>
          </div>

          <div v-else class="contact">
            <button class="contact-btn" @mouseenter="openContact" @click="openContact">
              {{ layout?.contact?.linkText }}
            </button>

            <div
              class="hidden"
              :class="{ opencontact: contactOpen }"
            >
              <div class="person-box">
                <person-short lines :person="layout?.contact?.person" icons />
                <Image :src="layout?.contact?.person?.bild" />
              </div>
              <div class="person-box">
                <person-short lines :person="layout?.contact2?.person" icons />
                <Image :src="layout?.contact2?.person?.bild" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="constrain banner-content">
        <line-box
          v-if="content?.headertext"
          height="1px"
          class="header-text z1"
          v-html="content?.headertext"
        />

        <Hamburger
          class="md:hidden hamburger"
          :class="{ active: mobile }"
          @click="toggle()"
        />
      </div>
      <div class="constrain language">
        <Language />
      </div>
    </section>

    <div id="nav">
      <div class="relative constrain flex items-center text-sm justify-end">
        <nav class="inner flex">
          <transition-group name="default">
            <div
              v-for="link of navigation?.links"
              :key="link.id"
              class="link-box relative"
            >
              <Go
                v-if="link.id == 459"
                :to="formLink.uri"
                class="px-4 link"
                :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
                v-text="link.title"
              />
              <Go
                v-else
                :to="link.uri"
                class="px-4 link"
                :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
                v-text="link.title"
              />
              <div class="sublinks">
                <line-box v-if="link.subs?.length" color="var(--color-green)" class="box">
                  <Go
                    v-for="sublink of link.subs"
                    :key="sublink.id"
                    :to="sublink.uri"
                    class="px-4 link sublink"
                    :class="{ active: sublink.uri === $store.state.router.page.meta?.uri }"
                    v-text="sublink.title"
                  />
                </line-box>
              </div>
            </div>
          </transition-group>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import LogoGreenBlue from '@/assets/images/XST001_Logo_gruen_cmyk.svg';
import LogoGreen from '@/assets/images/XST001_Logo_gruen_cmyk_neg.svg';
import LogoBlue from '@/assets/images/XST001_Logo_blau_cmyk_neg.svg';
import LogoOrange from '@/assets/images/XST001_Logo_orange_cmyk_neg.svg';
import LogoPurple from '@/assets/images/XST001_Logo_violett_cmyk_neg.svg';
import Mail from '@/assets/images/mail.svg?inline';
import Phone from '@/assets/images/phone.svg?inline';

import Scrolllock from '@/hooks/scrolllock';
import Language from '@/components/utils/Language.vue';
import Hamburger from '@/components/utils/Hamburger.vue';
import Image from '@/components/utils/Image.vue';
import LineBox from '../utils/LineBox.vue';

SwiperCore.use([Pagination]);

export default {
  components: {
    Language,
    Hamburger,
    Image,
    Swiper,
    SwiperSlide,
    LineBox,
    LogoGreen,
    LogoBlue,
    LogoOrange,
    LogoPurple,
    LogoGreenBlue,
    Mail,
    Phone,
  },
  setup() {
    const { lock, unlock } = Scrolllock();
    const store = useStore();
    const mobile = ref(false);
    const contactOpen = ref(false);

    const toggleContact = () => { contactOpen.value = !contactOpen.value; };
    const openContact = () => { contactOpen.value = true; };
    const closeContact = () => { contactOpen.value = false; };

    const toggle = () => {
      if (mobile.value) {
        mobile.value = false;
        setTimeout(() => unlock(), 400);
      } else {
        mobile.value = true;
        setTimeout(() => lock(), 400);
      }
    };

    const logo = computed(() => {
      const color = store.state.router.page?.content?.seitenfarbe;
      return {
        green: mobile.value ? LogoGreenBlue : LogoGreen,
        blue: LogoBlue,
        purple: LogoPurple,
        orange: LogoOrange,
      }[color] || LogoGreen;
    });

    const getPath = (locale) => store.state.router.page.meta?.translatedPaths[locale];
    const swapLanguage = (locale) => store.dispatch('router/push', { uri: getPath(locale) });
    const languages = computed(
      () => store.state.router.locales.filter(
        (l) => l !== store.state.router.locale,
      ),
    );

    const formLink = computed(
      () => store.state.router.layout.navigation.links.filter(
        (l) => l.id === '459',
      )[0].subs[0],
    );

    const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

    return {
      lock,
      unlock,
      mobile,
      toggle,
      contactOpen,
      openContact,
      closeContact,
      toggleContact,
      logo,
      getPath,
      swapLanguage,
      languages,
      capitalize,
      content: computed(() => store.state.router.page?.content),
      layout: computed(() => store.state.router.layout),
      navigation: computed(() => store.state.router.layout.navigation),
      formLink,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_type.scss";

:deep(.swiper-pagination-bullets) {
  bottom: px(30);

  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: rgb(112, 111, 111);
  }
}

.opencontact {
  display: block;
}

.band {
  --corner-size: 8rem;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  color: white;
  opacity: 1;
  transition: opacity var(--speed-fast) ease;
  z-index: 10;

  @supports not (aspect-ratio: 1 / 1) {
    &::before {
     width: 420px;
    }

    &.open,
    &.mobile {
      &::before,
      &::after {
        @include responsive('width', 100vh, 300%);
      }
    }

    &.mobile {
      &::before,
      &::after {
        @include responsive('width', max(100vh, #{px(1000)}), 250%);
      }
    }
  }

  &::before,
  &::after {
    --corner-diag: calc(var(--corner-size) * 1.414 / 2);

    content: "";
    position: absolute;
    left: 0;
    top: 0;
    aspect-ratio: 1 / 1;
    z-index: 10;
    opacity: 1;
    clip-path: polygon(var(--corner-size) 0, 100% 0, 0 100%, 0 var(--corner-size));
    transition:
      opacity var(--speed-slow) ease,
      height var(--speed-slow) ease;
    background: linear-gradient(
      135deg,
      var(--color-blue) var(--corner-diag) 30%,
      rgb(0, 60, 84) 45%,
    );

    @include responsive('height', max(110%, #{px(350)}), max(110%, #{px(420)}));
  }

  &::after {
    opacity: 0;
    background: rgba(255, 255, 255, 0.9);
  }

  .content {
    position: relative;
    top: px(65);
    left: px(50);
    z-index: 20;
    max-width: calc(100vw - #{px(80)});
  }

  .contact {
    display: flex;
    column-gap: 2rem;
    margin-top: px(50);
    width: 100%;
    @include responsive('flex-direction', column, row);
  }

  .person-box {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-2rem);
    transition:
      opacity var(--speed-fast) ease,
      transform var(--speed-fast) ease;

    :deep(.m1) {
      font-size: pt(12);
      line-height: pt(16);
      @apply font-title;
      font-weight: normal;
    }

    :deep(.m2) {
      font-size: pt(12);
      line-height: pt(16);
    }

    @include responsive('min-width', unset, px(350));
    @include responsive('width', 100%, min-content);

    :deep(img) {
      @apply rounded-full;
      margin-top: 1rem;
      width: 4em;
      height: 4em;
      filter: grayscale(1);
    }
  }

  .contact-btn {
    @extend .k1;

    position: relative;
    z-index: 20;
    color: white;
    padding: px(10) px(20);
    width: max-content;
    height: min-content;
    flex: 1 0 auto;
    cursor: pointer;

    &::before {
      content: "";
      @include full-overlay();
      clip-path: polygon(30% 0, 70% 0, 40% 100%, 0 100%);
      border-top: 1px solid var(--color-area);
      border-bottom: 1px solid var(--color-area);
      transition: clip-path var(--speed-fast) ease;
    }

    &:hover {
      &::before {
        clip-path: polygon(30% 0, 100% 0, 70% 100%, 0 100%);
      }
    }
  }

  &.open,
  &.mobile {
    opacity: 0.9;

    .person-box {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
    }
    &::before,
    &::after {
      @include responsive('height', 100vh, 300%);
    }
  }

  &.mobile {
    opacity: 1;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }

    &::before,
    &::after {
      @include responsive('height', max(100vh, #{px(1000)}), 250%);
    }
  }
}

.logo {
  position: relative;
  width: px(130);
  height: auto;
  left: 2rem;
  z-index: 100;

  a {
    display: flex;
    flex-direction: column;
  }

}

#nav {
  top: 0;
  z-index: 100;
  width: 100vw;
  padding-top: px(10);
  pointer-events: none;
  margin-top: px(6);

  @media (max-width: theme("screens.md")) {
    padding-top: 20px;

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
}

.inner,
.mobile-nav,
:deep(.Language),
.hamburger {
  pointer-events: all;
}

.link-box {
  .link {
    font-size: pt(12);

    &.active {
      @apply text-blue;
    }

    &.sublink {
      padding: 0;
      margin: 0;
    }
  }

  .sublinks {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    padding-top: 1rem;
    position: absolute;
    left: 0;
    top: 100%;
    width: max-content;
    opacity: 0;
    transform: translateY(2rem);
    pointer-events: none;
    z-index: 10;
    padding: 1rem;
    transition:
      transform var(--speed-fast) ease,
      opacity var(--speed-fast) ease;

    .box {
      display: flex;
      flex-flow: column;
      row-gap: .5rem;
    }
  }

  &.open,
  &:hover {
    .sublinks {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }
}

/* MOBILE NAV */
.backdrop {
  --corner-size: 3rem;

  content: "";
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  aspect-ratio: 1 / 1;
  height: 100vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  clip-path: polygon(var(--corner-size) 0, 100% 0, 0 100%, 0 var(--corner-size));

  &.active {
    background: rgba(255, 255, 255, 0.8);
    pointer-events: all;
  }
}

.mobile-nav {
  z-index: 99;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  min-height: 100vh;
  width: 100%;
  font-size: 1.5rem;
  color: black;

  a {
    padding: 0.4rem 0;
    text-decoration: none !important;
  }

  .menu-header {
    position: relative;
    padding: 0.8rem 1rem;

    &::before {
      @include full-overlay();
      content: "";
      left: 1rem;
      width: 3rem;
      height: 2px;
      background: var(--color-area);
    }
  }

  .menu {
    position: relative;
    display: flex;
    padding: 0.8rem 1rem;
    flex-direction: column;

    &::before,
    &::after {
      @include full-overlay();
      content: "";
      left: 1rem;
      width: 3rem;
      height: 2px;
      background: var(--color-area);
    }

    &::after {
      bottom: 0;
      top: unset;
    }

    .menu-item {
      display: block;
      padding: 0.3rem 0;
      cursor: pointer;

      &.active {
        color: var(--color-darkblue);
      }
    }

    .submenu {
      position: relative;
      padding: .5rem 0 2rem 0;

      .menu-item.sub {
        &::before {
          content: "+ "
        }
      }

      &::after {
        @include full-overlay();
        content: "";
        top: unset;
        bottom: 1rem;
        width: 3rem;
        height: 2px;
        background: var(--color-area);
      }
    }
  }
}

#banner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @include responsive('height', px(380), max(32vh, #{px(350)}));

  &::after {
    content: "";
    @include full-overlay();
    box-shadow: inset 0px -37px 31px -30px #ccc;
    pointer-events: none;
  }

  .banner-swiper {
    @include full-overlay();
    z-index: 0;

    .swiper-slide :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.7;
    }
  }

  .banner-content {
    display: flex;
    pointer-events: none;

    .header-text {
      position: relative;
      pointer-events: none;
      margin-left: max(20%, #{px(400)});
      align-self: center;
      white-space: pre-line;
      @include responsive('display', none, block);
    }
  }
}

.language {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @include responsive('display', none, block);

  .Language {
    margin: 0;
    margin-left: auto;
    top: px(40);
  }
}

.hamburger {
  position: absolute;
  top: px(20);
  right: px(20);
  z-index: 100;
  transform: scale(0.8);
  cursor: pointer;
}

.social {
  padding-left: 1rem;
}
</style>
