import { createApp, watch } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';
import LineBox from '@/components/utils/LineBox.vue';
import PersonShort from '@/components/utils/PersonShort.vue';
import XstandingLink from '@/components/utils/XstandingLink.vue';
import Slider from '@/components/utils/Slider.vue';
import Accordion from '@/components/utils/Accordion.vue';
import Dropdown from '@/components/utils/Dropdown.vue';
import Popup from '@/components/utils/Popup.vue';
import Recaptcha from '@/components/utils/Recaptcha.vue';
import CookieConsent from '@/plugins/cookie-consent';

import store from './store';
import checkView from './vue-check-view';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
app.use(VueToast, { position: 'top-right' });

// cookie consent
app.use(CookieConsent, undefined);
watch(() => store.state.router.locale, (locale) => {
  if (!locale) return;
  app.config.globalProperties.$cookieConsent.setLanguage(locale, true);
});

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');
app.config.globalProperties.$tryCDN = (url) => url;

//  if (url && url.includes('backend.jkweb.dev')) {
//  const el = document.createElement('a');
//  el.href = url;
//  return `https://jkweb-dev.b-cdn.net${el.pathname}${width ? `?width=${width}` : ''}`;
// }

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);
app.component('LineBox', LineBox);
app.component('PersonShort', PersonShort);
app.component('XstandingLink', XstandingLink);
app.component('Slider', Slider);
app.component('Accordion', Accordion);
app.component('Dropdown', Dropdown);
app.component('Popup', Popup);
app.component('Recaptcha', Recaptcha);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
