<template>
  <div
    class="line-box"
    :style="cssVars({
      lineColor: color || null,
      lineWidth: width,
      lineHeight: height,
    })"
    :class="{ disabled }"
  >
    <slot />
  </div>
</template>

<script>
import { cssVars } from '@/utils/css';

export default {
  props: {
    color: { type: String, default: null },
    width: { type: String, default: '3rem' },
    height: { type: String, default: '1px' },
    disabled: { type: Boolean, default: false },
  },
  setup() {
    return {
      cssVars,
    };
  },
};
</script>

<style lang="scss" scoped>

.line-box {
  position: relative;
  padding: 1rem 0;

  &::before {
    content: "";
    border-top: var(--line-height) solid var(--line-color, var(--color-area));
    @include full-overlay();
    width: var(--line-width);
    pointer-events: none;
    transition: border-color var(--speed-fast) ease;
  }

  &::after {
    content: "";
    border-bottom: var(--line-height) solid var(--line-color, var(--color-area));
    @include full-overlay();
    width: var(--line-width);
    pointer-events: none;
    transition: border-color var(--speed-fast) ease;
  }

  &.disabled {
    padding: 0;

    &::before,
    &::after {
      content: none;
    }
  }
}
</style>
