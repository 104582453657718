<template>

  <div class="app-content" :class="content?.seitenfarbe">
    <div id="overlay" />

    <Navigation />

    <main>
      <Dynamic v-if="parts" :inject="parts" transition />
    </main>
  </div>

</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Navigation from '@/components/structure/Navigation.vue';
import Footer from '@/components/structure/Footer.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {

    Navigation,
    Footer,
    Dynamic,

  },

  provide() {
    return {
      tryCDN: this.$tryCDN,
    };
  },

  setup() {
    dynamicPage();
    const store = useStore();
    const footer = computed(() => store.state.router.layout?.footer);
    const content = computed(() => store.state.router.page?.content);
    const parts = computed(() => content.value?.elemente?.concat(footer.value));
    return {
      parts,
      content,
    };
  },

};
</script>
