<template>
  <div ref="originRef" class="origin">
    <slot
      name="origin"
      :isOpen="isOpen"
      :open="openPopup"
      :close="closePopup"
    />
    <teleport to="body">
      <transition name="fly-in">
        <div v-if="isOpen" class="fly-in">
          <div class="backdrop" :class="{ open: isOpen }" />
          <div
            ref="contentRef"
            class="content"
            :class="{ open: isOpen }"
            :style="cssVars({
              originLeft: origin.left,
              originTop: origin.top,
              originWidth: origin.width,
              originHeight: origin.height,
              ratio,
            })"
          >
            <slot
              :isOpen="isOpen"
              :open="openPopup"
              :close="closePopup"
            />
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import {
  nextTick, onMounted, onUnmounted, reactive, ref, toRefs, watch,
} from 'vue';
import scrolllock from '@/hooks/scrolllock';
import { useOptionalModel } from '@/hooks/model';
import { cssVars } from '@/utils/css';
import { theme } from '@/utils/tailwind';
import { useWindowSize } from '@/hooks/observer';

export default {
  props: {
    open: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const originRef = ref(null);
    const contentRef = ref(null);
    const isOpen = useOptionalModel(toRefs(props), emit, 'open');
    const { lock, unlock } = scrolllock();
    const origin = reactive({});
    const ratio = ref(1);

    const openPopup = async () => {
      const rect = originRef.value?.getBoundingClientRect();
      origin.left = rect.left;
      origin.top = rect.top;
      origin.width = rect.width;
      origin.height = rect.height;
      await nextTick();

      isOpen.value = true;
      lock();
    };

    const closePopup = async () => {
      unlock();
      isOpen.value = false;
    };

    const closeOnEscape = (e) => {
      if (e.keyCode === 27) {
        closePopup();
      }
    };

    const updateOpenState = async () => {
      if (props.open) await openPopup();
      else await closePopup();
    };

    watch(() => props.open, updateOpenState);

    const updateRatio = () => {
      const toPx = (rem) => rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize);
      const styles = window.getComputedStyle(document.documentElement);
      const currentContainerWidth = Math.min(parseFloat(theme().screens.xl), window.innerWidth);
      const popupTargetWidth = currentContainerWidth - 2
        * toPx(parseFloat(styles.getPropertyValue('--contain-padding')));
      ratio.value = originRef.value?.getBoundingClientRect().width / popupTargetWidth;
    };

    useWindowSize(updateRatio);
    onMounted(() => {
      updateRatio();
      updateOpenState();

      window.addEventListener('keyup', closeOnEscape);
    });

    onUnmounted(() => {
      window.removeEventListener('keyup', closeOnEscape);
    });

    return {
      contentRef,
      originRef,
      isOpen,
      openPopup,
      closePopup,
      origin,
      ratio,
      cssVars,
    };
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  @include full-overlay();
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  opacity: 0;
  transition: opacity var(--speed-slow) ease;

  &.open {
    opacity: 1;
  }
}

.content {
  --content-width: calc(var(--current-width) - var(--contain-padding) * 2);
  z-index: 1001;
  position: absolute;
  width: var(--content-width);
  max-height: 90%;
  overflow: hidden;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(1);
  will-change: left, top, transform;
  display: flex;
  flex-flow: column;
}

.fly-in-enter-active,
.fly-in-leave-active,
.fly-in-enter-active .content,
.fly-in-leave-active .content {
  transition: all .5s ease;
}

.fly-in-enter-to .content,
.fly-in-leave-from .content,
.content.open {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.fly-in-enter-from .content,
.fly-in-leave-to .content {
  left: calc((var(--origin-left) + var(--origin-width) / 2) * 1px);
  top: calc((var(--origin-top) + var(--origin-height) / 2) * 1px);
  transform: translate(-50%, -50%) scale(var(--ratio));
}

.fly-in {
  @include full-overlay();
  position: fixed;
  z-index: 101;
}

.fly-in-enter-to,
.fly-in-leave-from {
  opacity: 1;
}
.fly-in-enter-from,
.fly-in-leave-to {
  opacity: 0;
}

</style>
