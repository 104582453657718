<template>
  <div
    class="dropdown"
    :aria-expanded="isOpen"
    :style="{
      '--content-height': `${contentHeight}px`,
      disabled
    }"
  >
    <div class="header" @click="toggle">
      <slot name="header" :expanded="isOpen" />
    </div>
    <div class="collapse">
      <div ref="content" class="content">
        <slot :expanded="isOpen" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  toRefs,
  onMounted,
} from 'vue';
import {
  useOptionalModel,
} from '@/hooks/model';

function onResize(elem, fn, opts = { box: 'border-box' }) {
  const observer = new ResizeObserver((entries) => {
    entries.forEach(fn);
  });

  observer.observe(elem, opts);
}

export default {
  props: {
    expanded: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup(props, context) {
    const isOpen = useOptionalModel(toRefs(props), context.emit, 'expanded');
    const content = ref(null);
    const contentHeight = ref(0);
    const toggle = () => {
      if (!props.disabled) {
        isOpen.value = !isOpen.value;
      }
    };

    onMounted(() => {
      onResize(content.value, (entry) => {
        contentHeight.value = entry.contentRect.height;
      });
    });

    return {
      toggle,
      isOpen,
      content,
      contentHeight,
    };
  },
};
</script>

<style>
:root {
  --content-height: 0;
}

.collapse {
  transition: max-height var(--speed-slow);
  -webkit-transition: max-height var(--speed-slow);
  max-height: 0;
  overflow: hidden;
}

.dropdown[aria-expanded=true] .collapse {
  max-height: var(--content-height);
}
</style>
