<template>
  <div
    class="g-recaptcha"
    data-size="invisible"
  />
</template>

<script>
export default {};
</script>

<style>
.g-recaptcha {
  position: relative;
  z-index: 100;
}
</style>
