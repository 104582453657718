/* eslint-disable */
function getPlugin() {

    const ClassNames = {
        Full: 'view-in--full',
        In: 'view-in',
        GtHalf: 'view-in--gt-half',
        GtThird: 'view-in--gt-third',
        Up: 'view-up',
        Down: 'view-down',
        Out: 'view-out',
        Above: 'view-out--above',
        Below: 'view-out--below',
        Threshold: 'view-in--threshold',
    };

    const EventTypes = {
        Enter: 'enter',
        Exit: 'exit',
        Progress: 'progress',
    };

    function throttle(handler, timeout) {
        timeout = typeof timeout !== 'undefined' ? timeout : 0;
        if (!handler || typeof handler !== 'function') throw new Error('Throttle handler argument is not incorrect. Must be a function.');
        let timeoutTime = 0;
        return function (e) {
            if (timeoutTime) return;
            timeoutTime = setTimeout(() => {
                timeoutTime = 0;
                handler(e);
            }, timeout);
        };
    }

    function roundPercent(v) {
        return (v * 1000 | 0) / 1000;
    }

    function objectAssign(obj, src) {
        for (const key in src) {
            if (src.hasOwnProperty(key)) obj[key] = src[key];
        }
        return obj;
    }

    function createInstance(Vue, options) {
        options = objectAssign({ throttleInterval: 16 }, options); // 60fps
        const items = {};
        const scrollThrottledHandler = throttle(scrollHandler, options.throttleInterval);
        let scrollValue = window.pageYOffset;
        let itemIndex = 0;

        window.addEventListener('review', scrollThrottledHandler);
        window.addEventListener('scroll', scrollThrottledHandler, { passive: true });
        if (ResizeObserver) new ResizeObserver(scrollThrottledHandler).observe(document.body);
        else window.addEventListener('resize', scrollThrottledHandler);

        function scrollHandler(e) {
           
            const scrollPosition = e?.target?.scrollTop || window.pageYOffset;

            const viewportTop = scrollPosition;
            const viewportBottom = scrollPosition + window.document.documentElement.clientHeight;
            const viewportHeight = window.document.documentElement.clientHeight;
            const documentHeight = window.document.documentElement.scrollHeight;
            const scrollPercent = roundPercent(scrollPosition / (documentHeight - viewportHeight));

            scrollValue = viewportTop - scrollValue;

            function getInType(i) {
                const rect = i.element?.getBoundingClientRect();
                const elementTop = rect.top + viewportTop;
                const elementBottom = elementTop + rect.height;
                const topIn = elementTop > viewportTop && elementTop < viewportBottom;
                const bottomIn = elementBottom > viewportTop && elementBottom < viewportBottom;
                const isWithin = elementTop < viewportTop && elementBottom > viewportBottom;
                const visible = topIn || bottomIn || isWithin
                    ? ((bottomIn ? elementBottom : viewportBottom) - (topIn ? elementTop : viewportTop))
                    : 0;
                const percentInView = visible / rect.height;
                const percentVisible = visible / viewportHeight;
                const centerPercent = (elementTop - viewportTop + rect.height / 2) / viewportHeight;
                const zeroPoint = viewportTop - rect.height;
                const topPercent = (elementTop - zeroPoint) / (viewportBottom - zeroPoint);
                const isAbove = percentInView === 0 && elementTop < viewportTop;
                const isBelow = percentInView === 0 && elementTop > viewportTop;

                return [
                    (topIn ? 1 : 0) | (bottomIn ? 2 : 0) | (isAbove ? 4 : 0) | (isBelow ? 8 : 0) | (isWithin ? 16 : 0),
                    roundPercent(percentInView),
                    roundPercent(percentVisible),
                    roundPercent(centerPercent),
                    roundPercent(topPercent),
                    rect];
            }

            for (const id in items) {
                const i = items[id];
                const inType = getInType(i);
                const direction = scrollValue <= 0
                    ? scrollValue === 0 ? 0 : 1
                    : 2;

                let insideThreshold = 0;

                if (typeof options.insideThreshold === 'function') {
                    insideThreshold = options.insideThreshold() || 0;
                } else {
                    insideThreshold = options.insideThreshold || 0;
                }

                const type = inType[0];
                const percentInView = inType[1];
                const percentVisible = inType[2];
                const percentCenter = inType[3];
                const percentTop = inType[4];
                const rect = inType[5];
                var { classes } = i;
                var { classList } = i.element;
                const inViewChange = i.percent <= insideThreshold && percentInView > insideThreshold;
                const outViewChange = i.percent > insideThreshold && percentInView <= insideThreshold;
                const inThreshold = percentInView > insideThreshold || percentVisible > insideThreshold;
          
                if (percentInView === 0 && i.percent === 0) continue;
                i.rect = rect;

                const eventType = (inViewChange && EventTypes.Enter) || (outViewChange && EventTypes.Exit) || EventTypes.Progress;

                Object.keys(classes).forEach((v) => {
                    classes[v] = false;
                });

                if (i.direction === undefined || inViewChange || outViewChange) {
                    i.direction = direction;
                }

                if (percentInView >= 0.5) {
                    classes[ClassNames.GtHalf] = true;
                } else if (percentInView >= 0.3) {
                    classes[ClassNames.GtThird] = true;
                }

                if (type === 16) {
                    classes[ClassNames.In] = true;
                }
                if (type === 8) {
                    classes[ClassNames.Below] = true;
                    classes[ClassNames.Out] = true;
                } else if (type === 4) {
                    classes[ClassNames.Above] = true;
                    classes[ClassNames.Out] = true;
                } else if (type === 3) {
                    classes[ClassNames.Full] = true;
                    classes[ClassNames.In] = true;
                } else if (type === 1) {
                    classes[ClassNames.In] = true;
                } else if (type === 2) {
                    classes[ClassNames.In] = true;
                }

                if (inThreshold) {
                    classes[ClassNames.Threshold] = true;
                }

                classes[ClassNames.Up] = i.direction === 1;
                classes[ClassNames.Down] = i.direction === 2;

                Object.keys(classes).forEach((n) => {
                    classList.toggle(n, classes[n]);
                    if (!classes[n]) delete classes[n];
                });

                if (typeof i.handler === 'function') {
                    i.handler({
                        type: eventType,
                        percentInView,
                        percentTop,
                        percentCenter,
                        scrollPercent,
                        scrollValue,
                        target: i,
                    });
                }

                if (typeof i.onceenter === 'function' && eventType === EventTypes.Enter) {
                    i.onceenter({
                        type: eventType,
                        percentInView,
                        percentTop,
                        percentCenter,
                        scrollPercent,
                        scrollValue,
                        target: i,
                    });
                    delete i.onceenter;
                    if (!i.persist) delete items[id];
                }

                i.percent = percentInView;
                i.type = type;
            }

            scrollValue = viewportTop;
        }

        Vue.directive('view', {
            mounted(element, bind) {
                const id = element.$scrollId || (`scrollId-${itemIndex++}`);
                const item = items[id] || {
                    element, classes: {}, percent: -1, rect: {},
                };

                if (bind.modifiers && bind.modifiers.once) {
                    item.onceenter = bind.value || function () {};
                } else {
                    item.persist = true;
                    item.handler = bind.value;
                }

                element.$scrollId = id;
                items[id] = item;
                scrollThrottledHandler();
            },
            unmounted(element, bind) {
                delete items[element.$scrollId];
            },

        });

        Vue.directive('scroll-trigger', {
            mounted(element) {
                element.addEventListener('scroll', scrollThrottledHandler, { passive: true });
            },
            unmounted(element) {
                element.removeEventListener('scroll', scrollThrottledHandler);
            },
        });

    }

    return {
        install(Vue, options) {
            Vue.directive('view', createInstance(Vue, options));
        },
    };
}

if (typeof exports === 'object' && typeof module !== 'undefined') {
    module.exports = getPlugin();
} else if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(getPlugin(), { option: 'custom option of client' });
}
