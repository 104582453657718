<template>
  <footer class="Footer bg-yellow">
    <div class="constrain">
      <div class="row row-12 text-sm">
        <div class="col-12">
          <div class="row row-7 content">
            <section class="col-7 md:col-4 locations">
              <div
                v-for="location in payload.locations"
                :key="location.id"
                class="link-section"
              >
                <div v-if="location.addresse" >
                    <a v-if="location.addresse" :href="location.googlemapsurl"  target="_blank">
                      <span v-if="location.name" class="k2i">
                        {{ location.name }}
                      </span>
                      <span  class="k2" v-html="location.addresse" />
                    </a>
                </div>
                <div
                  v-else
                  class="link-section"
                >
                  <span v-if="location.name" class="k2i">
                    {{ location.name }}
                  </span>
                  <span v-if="location.telefon" class="k2">
                    <a :href="'tel:'+location.telefon">{{ location.telefon }}</a>
                  </span>
                  <span v-if="location.mail" class="k2">
                    <a :href="'mailto:'+location.mail">{{ location.mail }}</a>
                  </span>
                </div>
                <div class="social">
                  <Go v-if="location.linkedin" :to="location.linkedin">
                    <linkedin />
                  </Go>
                  <Go v-if="location.xing" :to="location.xing">
                    <xing />
                  </Go>
                  <Go v-if="location.instagram" :to="location.instagram">
                    <instagram />
                  </Go>
                </div>
              </div>
            </section>

            <section class="col-7 md:col-1 md:offset-1 link-section">
              <span class="k2i">
                {{ payload.offersText }}
              </span>
              <Go
                v-for="offer in payload.offers"
                :key="offer.id"
                :to="offer.link"
                class="k2"
              >
                <template v-if="offer.footertitel">
                  {{ offer.footertitel }}
                </template>
                <template v-else>
                  {{ offer.title }}
                </template>
              </Go>
            </section>

            <section class="col-7 md:col-1 link-section">
              <span class="k2i">
                {{ payload.copyright }}
              </span>
              <Go :to="payload.impressum.link" class="k2">
                {{ payload.impressum.linkName }}
              </Go>
              <Go :to="payload.datenschutz.link" class="k2">
                {{ payload.datenschutz.linkName }}
              </Go>
              <Go :to="payload.agb.link" class="k2">
                {{ payload.agb.linkName }}
              </Go>
              <p>
                <a
                  class="k2"
                  role="button"
                  @click="$cookieConsent.showPreferences()"
                >
                  Cookies
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Mail from '@/assets/images/mail.svg?inline';
import Phone from '@/assets/images/phone.svg?inline';
import Linkedin from '@/assets/images/linkedin.svg?inline';
import Xing from '@/assets/images/xing.svg?inline';
import Instagram from '@/assets/images/instagram.svg?inline';
import { cssVars } from '@/utils/css';

export default {
  components: {
    Mail, Phone, Linkedin, Xing, Instagram,
  },
  props: { payload: { type: Object, default: () => ({}) } },
};
</script>

<style scoped lang="scss">
.Footer {
  @apply text-white;

  background: var(--gradient-blue);

  @include responsive('padding-top', px(20), px(62));
  @include responsive('padding-bottom', px(20), px(52));

  a {
    @apply text-white;

    &:hover {
      color: var(--color-active);
    }
  }
}

.content {
  row-gap: 2rem;
  --gutter-x: 3rem
}

.credit {
  color: #B29B3A;
}

.credit-link:not(:last-child)::after {
  content: ', ';
  color: #B29B3A;
}

.link-section {
  display: flex;
  flex-flow: column;
  row-gap: 0;
}

.social {
  display: flex;
  column-gap: 0.7rem;
  margin-top: px(10);

  svg {
    width: px(30);
    height: px(30);
    fill: white;
  }
}

.locations {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;

  & > * {
    width: max-content;
  }
}
</style>
