<template>
  <transition v-if="transition" name="appear" mode="out-in">
    <div :key="meta.uri" class="block-container">
      <component
        :is="component.component"
        v-for="component of inject.filter(c => c)"
        :id="component.slug || component.data.slug"
        :key="component.id || component.data.id"
        :payload="component.data || component"
        class="fade-item"
      />
    </div>
  </transition>
  <template v-else>
    <component
      :is="component.component"
      v-for="component of inject.filter(c => c)"
      :id="component.slug || component.data.slug"
      :key="component.id || component.data.id"
      :payload="component.data || component"
      class="fade-item"
    />
  </template>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';

const NotFound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));
const Footer = defineAsyncComponent(() => import('@/components/structure/Footer.vue'));

const Text = defineAsyncComponent(() => import('@/components/parts/Text.vue'));
const PageSlider = defineAsyncComponent(() => import('@/components/parts/PageSlider.vue'));
const TeamSlider = defineAsyncComponent(() => import('@/components/parts/TeamSlider.vue'));
const Team = defineAsyncComponent(() => import('@/components/parts/Team.vue'));
const AngeboteSlider = defineAsyncComponent(() => import('@/components/parts/AngeboteSlider.vue'));
const Zweispaltig = defineAsyncComponent(() => import('@/components/parts/Zweispaltig.vue'));
const Aufklappbar = defineAsyncComponent(() => import('@/components/parts/Aufklappbar.vue'));
const KontaktpersonenListe = defineAsyncComponent(() => import('@/components/parts/KontaktpersonenListe.vue'));
const Links = defineAsyncComponent(() => import('@/components/parts/Links.vue'));
const Fallmeldeformular = defineAsyncComponent(() => import('@/components/parts/Fallmeldeformular.vue'));
const Kontakt = defineAsyncComponent(() => import('@/components/parts/Kontakt.vue'));

export default {
  components: {

    NotFound,
    Footer,

    Team,
    Text,
    PageSlider,
    TeamSlider,
    AngeboteSlider,
    Zweispaltig,
    Aufklappbar,
    KontaktpersonenListe,
    Links,
    Fallmeldeformular,
    Kontakt,

  },
  props: {
    inject: { type: Array, default: () => [] },
    transition: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore();
    const meta = computed(() => store.state.router.page?.meta);
    return {
      meta,
    };
  },
};
</script>
