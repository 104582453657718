const colors = require('tailwindcss/colors');

module.exports = {
  purge: {
    content: [
      './public/**/*.html',
      './src/**/*.vue',
    ],
    options: {
      safelist: [
        // bg colors
        'bg-red',
      ],
    },
  },

  theme: {

    colors: {
      red: {
        DEFAULT: 'var(--color-red)',
      },
      blue: {
        DEFAULT: 'var(--color-blue)',
        dark: 'var(--color-darkblue)',
      },
      white: colors.white,
      black: colors.black,
    },

    extend: {
      fontFamily: {
        sans: ['Open Sans', 'sans-serif'],
        title: ['Merriweather', 'serif'],
      },
    },

    fontSize: {
      sm: '0.6rem', // = 18px
      base: '0.934rem', // = 28px
      lg: '1.067rem', // = 32px
      xl: '1.734rem', // = 52px
      //       sm: '0.667rem', // = 20px
      //       base: '1rem', // = 30px
      //       lg: '1.125rem',
      //       xl: '1.8rem', // 54px
    },

    screens: {
      sm: '640px',
      md: '920px',
      lg: '1500px',
      xl: '1800px',
    },

  },

  mapTheme: {
    styles: [
      {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [
          {
            hue: '#2c2e33',
          },
          {
            saturation: 7,
          },
          {
            lightness: 19,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#008eff',
          },
          {
            saturation: -93,
          },
          {
            lightness: 31,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            hue: '#bbc0c4',
          },
          {
            saturation: -93,
          },
          {
            lightness: 31,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [
          {
            hue: '#bbc0c4',
          },
          {
            saturation: -93,
          },
          {
            lightness: -2,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#e9ebed',
          },
          {
            saturation: -90,
          },
          {
            lightness: -8,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            hue: '#e9ebed',
          },
          {
            saturation: 10,
          },
          {
            lightness: 69,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            hue: '#e9ebed',
          },
          {
            saturation: -78,
          },
          {
            lightness: 67,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
    ],
  },

};
