<template>
  <line-box
    class="person"
    :disabled="!lines"
    :color="lineColor"
    :height="lineSize"
    :style="cssVars({ textColor })"
  >
    <div class="m1">
      {{ person.name }}
    </div>
    <div class="m2">
      {{ person.funktion }}
    </div>
    <div v-if="icons" class="social">
      <Go v-if="person.telefon" :to="`tel:${person.telefon}`">
        <phone />
      </Go>
      <Go v-if="person.mail" :to="`mailto:${person.mail}`">
        <mail />
      </Go>
      <Go v-if="person.linkedin" :to="person.linkedin">
        <linkedin />
      </Go>
      <Go v-if="person.xing" :to="person.xing">
        <xing />
      </Go>
    </div>
    <div v-else class="social text">
      <Go v-if="person.telefon" :to="`tel:${person.telefon}`">
        <span class="m3">
          {{ person.text.phone }}
        </span>
      </Go>
      <Go v-if="person.mail" :to="`mailto:${person.mail}`">
        <span class="m3">
          {{ person.text.mail }}
        </span>
      </Go>
      <Go v-if="person.linkedin" :to="person.linkedin">
        <span class="m3">
          {{ person.text.linkedIn }}
        </span>
      </Go>
      <Go v-if="person.xing" :to="person.xing">
        <span class="m3">
          {{ person.text.xing }}
        </span>
      </Go>
    </div>
  </line-box>
</template>

<script>
import Mail from '@/assets/images/mail.svg?inline';
import Phone from '@/assets/images/phone.svg?inline';
import Linkedin from '@/assets/images/linkedin.svg?inline';
import Xing from '@/assets/images/xing.svg?inline';
import { cssVars } from '@/utils/css';

export default {
  components: {
    Phone,
    Mail,
    Linkedin,
    Xing,
  },
  props: {
    person: { type: Object, default: () => ({}) },
    icons: { type: Boolean, default: false },
    lines: { type: Boolean, default: false },
    lineSize: { type: String, default: '1px' },
    textColor: { type: String, default: 'currentColor' },
    lineColor: { type: String, default: null },
  },
  setup() {
    return {
      cssVars,
    };
  },
};
</script>

<style lang="scss" scoped>
.person {
  color: var(--text-color, currentColor);
}
.social.text {
  column-gap: 1rem;

  & > * {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% + 0.5rem);
      transform: translateY(-50%);
      height: 1.2rem;
      width: 1px;
      background: currentColor;
    }

    &:last-child::before {
      content: none;
    }
  }
}
</style>
