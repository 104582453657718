/* eslint-disable max-len */
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

const defaultConfig = {
  guiOptions: {
    consentModal: {
      layout: 'bar',
      position: 'bottom center',
    },
  },

  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {},
  },

  language: {
    default: 'en',
    translations: {
      de: {
        consentModal: {
          title: 'Cookies und Datenschutzpräferenzen',
          description: 'Wir verwenden Cookies und ähnliche Technologien, um das Nutzererlebnis auf unserer Webseite zu verbessern, unseren Datenverkehr zu analysieren und Inhalte zu personalisieren.<br><br> Mehr Details erfahren Sie in unserer <a href="/de/info/datenschutz">Datenschutzerklärung</a> und in der Cookie Auswahl. Sie können Ihre Einwilligung auch jederzeit widerrufen.',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          showPreferencesBtn: 'Einstellungen',
        },
        preferencesModal: {
          title: 'Cookie-Einstellungen',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          savePreferencesBtn: 'Einstellungen bestätigen',
          closeIconLabel: 'Schliessen',
          sections: [
            {
              description: 'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten, wann immer Sie wollen. Weitere Einzelheiten zu Cookies und anderen sensiblen Daten finden Sie in der vollständigen <a href="/de/info/datenschutz">Datenschutzerklärung</a>.',
            },
            {
              title: 'Notwendige Cookies',
              description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Analyse Cookies',
              description: 'Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Sämtliche Daten werden anonymisiert und lassen keinen Rückschluss auf Ihre Person zu.',
              linkedCategory: 'analytics',
            },
          ],
        },
      },
      en: {
        consentModal: {
          title: 'Cookies and privacy preferences',
          description: 'We use cookies and similar technologies to improve the user experience on our website, analyze our traffic and personalize content.<br><br> You can find out more details in our <a href="/en/info/datenschutz">privacy policy</a> and in the cookie selection. You can also revoke your consent at any time.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Options',
        },
        preferencesModal: {
          title: 'Cookie-settings',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          sections: [
            {
              description: 'We use cookies to ensure the basic functionality of the website and to improve your online experience. For each category, you can choose to subscribe or unsubscribe whenever you want. Further details on cookies and other sensitive data can be found in the full <a href="/en/info/datenschutz">privacy policy</a>.',
            },
            {
              title: 'Necessary cookies',
              description: 'These cookies are essential for the proper functioning of the website.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Analytics cookies',
              description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics',
            },
          ],
        },
      },
    },
  },
};
export default {
  install: (app, pluginConfig = {}) => {
    app.config.globalProperties.$cookieConsent = CookieConsent;
    CookieConsent.run({
      ...defaultConfig,
      ...pluginConfig,
    });
  },
};
