<template>
  <div class="Language">
    <transition-group tag="ul" name="list" class="inner">
      <li
        v-for="locale in $store.state.router.locales"
        :key="locale"
        class="lang"
        :class="{ active: locale === $store.state.router.locale }"
      >
        <a :href="getPath(locale)" @click.prevent="swap(locale)">{{ names[locale] }}</a>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const getPath = (locale) => store.state.router.page.meta?.translatedPaths[locale];
    const swap = (locale) => store.dispatch('router/push', { uri: getPath(locale) });

    return {
      names: {
        de: 'Deutsch',
        en: 'English',
      },
      swap,
      getPath,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

.Language {
  position: relative;
  white-space: nowrap;
  width: fit-content;

  a {
    @extend .n1;
  }
}

.inner {
  display: flex;
  column-gap: px(20);

  .lang.active {
    @apply text-blue;
    padding-bottom: px(2);
    border-bottom: 1px solid currentColor;

  }
}
</style>
